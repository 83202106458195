import React from 'react';
import { Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import { others } from 'src/utils';
import HeadquartersComponent from './headquarters.component';

const CaracteristicsForm: React.FC = () => {
  const { Item } = Form;
  const { Option } = Select;

  return (
    <>
      <Divider />
      <h3>Características do Imóvel</h3>
      <HeadquartersComponent />
    </>
  );
};

export default CaracteristicsForm;
