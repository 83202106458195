import styled from 'styled-components';

export const SearchContainer = styled.div`
  position: absolute;
  top: 2rem;
  left: 4%;
  width: 100%;
  max-width: 392px;
  height: 48px;
  z-index: 10;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.3s ease-in;
  font-family: Roboto;
  input {
    border-radius: 8px 8px 0 0;
    border: none;
    border-bottom: 1px solid #dadce0;
    color: #000;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
    padding: 12px 16px 12px 16px;
    font-size: 16px;
    width: 100%;
    outline: none;
    transition: 0.3s ease-in;
  }
`;
