import styled from 'styled-components';
import { colors } from 'src/utils';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  border: none;
  padding: 0;

  background: none;
  &:focus {
    outline: none;
  }
  svg {
    outline: none;
  }

  a {
    height: 16px;
  }
` as any;

export default {
  eyeIcon: {
    color: colors.blue[500],
    height: 16,
  } as React.CSSProperties,

  eyeIconDisabled: {
    color: colors.gray[500],
    height: 16,
  } as React.CSSProperties,

  editIcon: {
    color: colors.orange[500],
    height: 16,
  } as React.CSSProperties,

  editIconDisabled: {
    color: colors.gray[400],
    height: 16,
  } as React.CSSProperties,
  trashIcon: {
    color: colors.red[400],
    height: 16,
    cursor: 'pointer',
    border: 'none',
  } as React.CSSProperties,
  trashIconDisabled: {
    color: colors.gray[500],
    height: 16,
  } as React.CSSProperties,
  approveIcon: {
    color: colors.green[400],
    height: 16,
    cursor: 'pointer',
    border: 'none'
  } as React.CSSProperties,

  approveIconDisabled: {
    color: colors.green[500],
    height: 16,
    cursor: 'pointer',
    border: 'none',
  } as React.CSSProperties,

  rejectIcon: {
    color: colors.red[400],
    height: 16,
    cursor: 'pointer',
    border: 'none'
  } as React.CSSProperties,

  rejectIconDisabled: {
    color: colors.red[500],
    height: 16,
    cursor: 'pointer',
    border: 'none'
  } as React.CSSProperties,
};
