import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY!);
Geocode.setLanguage('pt-br');
Geocode.setRegion('br');

const FuseAddress = async (addressString: string) => {
  let number: string;
  let street: string;
  let neighborhood: string;
  let city: string;
  let state: string;
  let zipcode: string;
  return Geocode.fromAddress(addressString)
    .then(async (res) => {
      const { lat, lng } = res.results[0].geometry.location;

      return Geocode.fromLatLng(lat, lng).then((response) => {
        // (let i = 0; i < response.results[0].address_components.length; i++)
        // tslint:disable-next-line: prefer-for-of
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          // tslint:disable-next-line: no-increment-decrement
          i++
        ) {
          // tslint:disable-next-line: prefer-for-of
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            // tslint:disable-next-line: no-increment-decrement
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'street_number':
                number = response.results[0].address_components[i].long_name;
                break;
              case 'route':
                street = response.results[0].address_components[i].long_name;
                break;
              case 'political' || 'sublocality' || 'sublocality_level_1':
                neighborhood =
                  response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_2' || 'political':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1' || 'political':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'postal_code':
                zipcode = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        console.log(response, lat, lng);
        return { number, street, neighborhood, city, state, zipcode, lat, lng };
      });
    })
    .catch((err) => {
      throw Error(err);
    });
};

export default FuseAddress;
