import { Col, Form, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { others } from 'src/utils';

const HeadquartersComponent: React.FC = () => {
  const { Item } = Form;
  const { Option } = Select;

  return (
    <>
      <Row gutter={16}>
        <Col md={4} lg={5} sm={12} xs={24}>
          <Item label="Quartos" name={['caracteristics', 'rooms']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={5} sm={12} xs={24}>
          <Item label="Suítes" name={['caracteristics', 'suites']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={5} sm={24} xs={24}>
          <Item label="Salas" name={['caracteristics', 'livingRooms']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={5} sm={12} xs={24}>
          <Item label="Banheiros" name={['caracteristics', 'bathrooms']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={4} sm={12} xs={24}>
          <Item label="Varandas" name={['caracteristics', 'balconies']}>
            <InputNumber />
          </Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={4} lg={5} sm={12} xs={24}>
          <Item label="Cozinhas" name={['caracteristics', 'kitchens']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={5} sm={12} xs={24}>
          <Item label="Lavabos" name={['caracteristics', 'toilets']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={5} sm={24} xs={24}>
          <Item label="Dependências" name={['caracteristics', 'dependencies']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={5} sm={12} xs={24}>
          <Item
            label="Área Técnicas"
            name={['caracteristics', 'technicalAreas']}
          >
            <InputNumber />
          </Item>
        </Col>

        <Col md={4} lg={4} sm={12} xs={24}>
          <Item label="Garagens" name={['caracteristics', 'garages']}>
            <InputNumber />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} lg={24} sm={24} xs={24}>
          <Item
            label="Outras características"
            name={['caracteristics', 'others']}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione outras características"
            >
              {others.map((other) => (
                <Option key={other.id} value={other.id}>
                  {other.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default HeadquartersComponent;
