const others = [
  { id: 1, name: 'Cozinha Americana' },
  { id: 2, name: 'Churrasqueira' },
  { id: 3, name: 'Madeira' },
  { id: 4, name: 'Quadra Esportiva' },
  { id: 5, name: 'Academia' },
  { id: 6, name: 'Aquecimento Gás' },
  { id: 7, name: 'Brinquedoteca' },
  { id: 8, name: 'Vinílico' },
  { id: 9, name: 'Bicicletário' },
  { id: 10, name: 'Closet' },
  { id: 11, name: 'Espaço Gourmet' },
  { id: 12, name: 'Porcelanato' },
  { id: 13, name: 'Câmera' },
  { id: 14, name: 'Piscina Infantil' },
  { id: 15, name: 'Depósito' },
];

export default others;
